import { type ChangeEvent, type FC } from 'react';
import { useCallback, useMemo } from 'react';
import {
  Checkbox,
  Icon,
  SearchResult,
  Table,
  TableColumns,
  tableRowClickEventWrapper,
  useRowActive,
  useTable,
  useTableRowHighlight,
} from '@fleet/shared';
import { Divider, Typography } from '@mui/material';
import { Row, useFilters, usePagination, useRowSelect } from 'react-table';
import { type InventoryClass } from 'dto/inventoryClass';
import { useDispatch, useSelector } from 'store/utils';
import {
  inventoryClassesSelector,
  inventoryClassFilterSelector,
} from 'features/inventoryClass/inventoryClassSelectors';
import { TableCaption } from '@fleet/shared';
import { PaginationParams } from '@fleet/shared/dto/pagination';
import { getInventoryClasses } from 'features/inventoryClass/inventoryClassActions';
import { TransLabel } from 'i18n/trans/label';
import { InventoryClassSearchForm } from 'routes/inventoryClass/InventoryClassSearchForm';
import { dataLoadingSelector } from 'features/common/commonSelectors';
import { Link, useHistory, useParams } from 'react-router-dom';

interface InventoryClassTableProps {}

export const InventoryClassTable: FC<InventoryClassTableProps> = () => {
  const { id } = useParams<{ id?: string }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(dataLoadingSelector);
  const inventoryClasses = useSelector(inventoryClassesSelector);
  const filter = useSelector(inventoryClassFilterSelector);
  const data = useMemo(
    () => inventoryClasses?.items ?? [],
    [inventoryClasses?.items]
  );

  const link = useCallback(
    (row: Row<InventoryClass>) => `/inventory-class/edit/${row.original.id}`,
    []
  );

  const columns: TableColumns<InventoryClass> = useMemo(
    () => [
      {
        accessor: 'name',
        Header: <TransLabel i18nKey="name" />,
        Cell: ({ row, value }) => (
          <Link to={link(row)} onClick={tableRowClickEventWrapper}>
            {value}
          </Link>
        ),
      },
      {
        accessor: 'description',
        Header: <TransLabel i18nKey="description" />,
      },
      {
        id: 'inventoryType',
        accessor: ({ inventoryType }) => inventoryType?.name,
        Header: <TransLabel i18nKey="inventoryType" />,
        width: 100,
      },
      {
        id: 'comfortLevel',
        accessor: ({ comfortLevel }) => comfortLevel?.name,
        Header: <TransLabel i18nKey="comfortClass" />,
        width: 100,
      },
      {
        id: 'serviceClass',
        accessor: ({ serviceClass }) => serviceClass?.name,
        Header: <TransLabel i18nKey="serviceLevel" />,
        width: 100,
      },
      {
        accessor: 'minOccupancy',
        Header: <TransLabel i18nKey="minOccupancy" />,
        width: 72,
      },
      {
        accessor: 'maxOccupancy',
        Header: <TransLabel i18nKey="maxOccupancy" />,
        width: 72,
      },
      {
        accessor: 'isSoldOnce',
        Header: <TransLabel i18nKey="soldOnce" />,
        Cell: ({
          row: {
            original: {
              isSoldOnce,
              inventoryType: { id },
            },
          },
        }) => {
          if (
            id === 'INVENTORY_TYPE.BED' ||
            id === 'INVENTORY_TYPE.COMPARTMENT'
          ) {
            return isSoldOnce ? (
              <Icon name="check" color="success" />
            ) : (
              <Icon name="close" color="error" />
            );
          }
          return null;
        },
        width: 80,
      },
      {
        accessor: 'isAssignmentEnabled',
        Header: <TransLabel i18nKey="seatAssignmentEnabled" />,
        Cell: ({
          row: {
            original: {
              isAssignmentEnabled,
              inventoryType: { id },
            },
          },
        }) => {
          if (id === 'INVENTORY_TYPE.SEAT' || id === 'INVENTORY_TYPE.BED') {
            return isAssignmentEnabled ? (
              <Icon name="check" color="success" />
            ) : (
              <Icon name="close" color="error" />
            );
          }
          return null;
        },
        width: 112,
      },
      {
        accessor: 'allowedNumberOfSplits',
        Header: <TransLabel i18nKey="splitsEnabled" />,
        Cell: ({
          row: {
            original: {
              allowedNumberOfSplits,
              inventoryType: { id },
            },
          },
        }) => <>{id === 'INVENTORY_TYPE.SEAT' && allowedNumberOfSplits}</>,
        width: 72,
      },
      {
        accessor: 'isActive',
        Header: <TransLabel i18nKey="isActive" />,
        Cell: (cell) =>
          cell.value ? (
            <Icon name="check" color="success" />
          ) : (
            <Icon name="close" color="error" />
          ),
        width: 100,
      },
      {
        accessor: 'isDefault',
        Header: <TransLabel i18nKey="isDefault" />,
        Cell: (cell) =>
          cell.value ? (
            <Icon name="check" color="success" />
          ) : (
            <Icon name="close" color="error" />
          ),
        width: 100,
      },
    ],
    [link]
  );

  const getRowId = useCallback((row) => `${row.id!}`, []);

  const handlePageChange = useCallback(
    async (paginationParams: PaginationParams) => {
      dispatch(getInventoryClasses({ ...filter, ...paginationParams }));
    },
    [filter, dispatch]
  );

  const table = useTable<InventoryClass>(
    {
      data,
      columns,
      getRowId,
      total: inventoryClasses?.totalCount,
      offset: inventoryClasses?.offset,
      onPageChange: handlePageChange,
    },
    useFilters,
    usePagination,
    useRowActive,
    useRowSelect
  );

  useTableRowHighlight(id, table);

  const handleActiveFilterToggle = useCallback(
    (e: ChangeEvent<HTMLInputElement>) =>
      table.setFilter('isActive', e.target.checked || undefined),
    [table]
  );

  return (
    <>
      <InventoryClassSearchForm />
      <Divider />
      <SearchResult results={data.length} loading={loading}>
        <Table
          caption={
            <TableCaption>
              <Checkbox
                size="small"
                label={
                  <Typography
                    variant="body2"
                    color="text.primary"
                    component="span"
                  >
                    <TransLabel i18nKey="activeInventoryClasses" />
                  </Typography>
                }
                onChange={handleActiveFilterToggle}
                inline
              />
            </TableCaption>
          }
          table={table}
          getRowProps={(_, { row }) => ({
            onClick: () =>
              history.push(link(row), {
                inventoryType: {
                  id: row.original.inventoryType?.id,
                  name: row.original.inventoryType?.name,
                },
              }),
          })}
        />
      </SearchResult>
    </>
  );
};
