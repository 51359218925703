import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransLabel = createTrans({
  active: <Trans i18nKey="label.active" defaults="Active" />,
  activeInventoryClasses: (
    <Trans
      i18nKey="label.showOnlyActiveInventoryClasses"
      defaults="Show only active inventory classes"
    />
  ),
  activity: <Trans i18nKey="label.activity" defaults="Activity" />,
  addComposition: (
    <Trans i18nKey="label.addComposition" defaults="Add composition" />
  ),
  addEditBlocking: (
    <Trans i18nKey="label.addEditBlocking" defaults="Add/edit blocking" />
  ),
  addFloor: <Trans i18nKey="label.addFloor" defaults="Add floor" />,
  addNewCarriage: (
    <Trans
      i18nKey="label.addNewCarriage"
      defaults="Drag and drop to add new carriage"
    />
  ),
  addNewClass: <Trans i18nKey="label.addNewClass" defaults="Add new class" />,
  addNewSeat: <Trans i18nKey="label.addNewSeat" defaults="Add new seat" />,
  addNewSpace: <Trans i18nKey="label.addNewSpace" defaults="+ Add new space" />,
  allChangesSaved: (
    <Trans i18nKey="label.allChangesSaved" defaults="All changes saved" />
  ),
  asCount: <Trans i18nKey="label.asCount" defaults="As count" />,
  asSingleUnit: (
    <Trans i18nKey="label.asSingleUnit" defaults="As single unit" />
  ),
  asc: <Trans i18nKey="label.asc" defaults="Ascending" />,
  berthNumber: <Trans i18nKey="label.berthNumber" defaults="Berth number" />,
  blockedOriginDestination: (
    <Trans
      i18nKey="label.blockedOriginDestination"
      defaults="Blocked origin and destination"
    />
  ),
  carriageDirection: (
    <Trans i18nKey="label.carriageDirection" defaults="Carriage direction" />
  ),
  carriageOrder: (
    <Trans i18nKey="label.carriageOrder" defaults="Carriage order" />
  ),
  changeExistingCarriage: (
    <Trans
      i18nKey="label.changeExistingCarriage"
      defaults="Drag and drop to change existing carriage"
    />
  ),
  chooseMounting: (
    <Trans i18nKey="label.chooseMounting" defaults="Choose mounting" />
  ),
  close: <Trans i18nKey="label.close" defaults="Close" />,
  closeModificationHistory: (
    <Trans
      i18nKey="label.closeModificationHistory"
      defaults="Close modification history"
    />
  ),
  code: <Trans i18nKey="label.code" defaults="Code" />,
  comfortClass: <Trans i18nKey="label.comfortClass" defaults="Comfort class" />,
  comfortLevel: <Trans i18nKey="label.comfortLevel" defaults="Comfort level" />,
  compartment: <Trans i18nKey="label.compartment" defaults="Compartment" />,
  compartmentNumber: (
    <Trans i18nKey="label.compartmentNumber" defaults="Compartment number" />
  ),
  connectedVehicles: (
    <Trans i18nKey="label.connectedVehicles" defaults="Connected vehicles" />
  ),
  connectionEndDate: (
    <Trans i18nKey="label.connectionEndDate" defaults="Connection end date" />
  ),
  connectionStartDate: (
    <Trans
      i18nKey="label.connectionStartDate"
      defaults="Connection start date"
    />
  ),
  contents: <Trans i18nKey="label.contents" defaults="Contents" />,
  count: <Trans i18nKey="label.count" defaults="Count" />,
  created: <Trans i18nKey="label.created" defaults="Created" />,
  dayFri: <Trans i18nKey="label.dayFri" defaults="Friday" />,
  dayMon: <Trans i18nKey="label.dayMon" defaults="Monday" />,
  daySat: <Trans i18nKey="label.daySat" defaults="Saturday" />,
  daySun: <Trans i18nKey="label.daySun" defaults="Sunday" />,
  dayThu: <Trans i18nKey="label.dayThu" defaults="Thursday" />,
  dayTue: <Trans i18nKey="label.dayTue" defaults="Tuesday" />,
  dayWed: <Trans i18nKey="label.dayWed" defaults="Wednesday" />,
  default: <Trans i18nKey="label.default" defaults="Default" />,
  departureDateTime: (
    <Trans i18nKey="label.departureDateTime" defaults="Departure time" />
  ),
  departureDays: (
    <Trans i18nKey="label.departureDays" defaults="Departure days" />
  ),
  departureIntervals: (
    <Trans i18nKey="label.departureIntervals" defaults="Departure intervals" />
  ),
  desc: <Trans i18nKey="label.desc" defaults="Descending" />,
  description: <Trans i18nKey="label.description" defaults="Description" />,
  destinationStop: (
    <Trans i18nKey="label.destinationStop" defaults="Destination stop" />
  ),
  dimensions: <Trans i18nKey="label.dimensions" defaults="Dimensions" />,
  direction: <Trans i18nKey="label.direction" defaults="Direction" />,
  down: <Trans i18nKey="label.down" defaults="Down" />,
  endDate: <Trans i18nKey="label.endDate" defaults="End date" />,
  enterLink: <Trans i18nKey="label.enterLink" defaults="Enter link..." />,
  even: <Trans i18nKey="label.even" defaults="Even number" />,
  exceptionDates: (
    <Trans i18nKey="label.exceptionDates" defaults="Exception dates" />
  ),
  fileUpload: <Trans i18nKey="label.fileUpload" defaults="File upload" />,
  find: <Trans i18nKey="label.find" defaults="Find" />,
  firstRouteStop: (
    <Trans i18nKey="label.firstRouteStop" defaults="First route stop" />
  ),
  floorNumber: (
    <Trans i18nKey="label.floorNumber" defaults="Floor {{number}}" />
  ),
  floors: <Trans i18nKey="label.floors" defaults="Floors" />,
  forward: <Trans i18nKey="label.forward" defaults="Forward" />,
  hasCompositions: (
    <Trans i18nKey="label.hasCompositions" defaults="Has compositions" />
  ),
  height: <Trans i18nKey="label.height" defaults="Height" />,
  hide: <Trans i18nKey="label.hide" defaults="Hide" />,
  home: <Trans i18nKey="label.home" defaults="Home" />,
  inventoryClass: (
    <Trans i18nKey="label.inventoryClass" defaults="Inventory class" />
  ),
  inventoryClassQty: (
    <Trans
      i18nKey="label.inventoryClassQty"
      defaults="{{num}} inventory classes"
    />
  ),
  inventorySubType: (
    <Trans i18nKey="label.inventorySubType" defaults="Inventory Subtype" />
  ),
  inventoryType: (
    <Trans i18nKey="label.inventoryType" defaults="Inventory type" />
  ),
  isActive: <Trans i18nKey="label.isActive" defaults="Is active" />,
  isCompositionModified: (
    <Trans
      i18nKey="label.isCompositionModified"
      defaults="Is composition modified"
    />
  ),
  isDefault: <Trans i18nKey="label.isDefault" defaults="Is default" />,
  isException: <Trans i18nKey="label.isException" defaults="Is exception" />,
  language: <Trans i18nKey="label.language" defaults="Language" />,
  lastModified: <Trans i18nKey="label.lastModified" defaults="Last modified" />,
  lastRouteStop: (
    <Trans i18nKey="label.lastRouteStop" defaults="Last route stop" />
  ),
  lineNumber: <Trans i18nKey="label.lineNumber" defaults="Line number" />,
  lineTemplateManagement: (
    <Trans
      i18nKey="label.lineTemplateManagement"
      defaults="Line template management"
    />
  ),
  logs: <Trans i18nKey="label.logs" defaults="Logs" />,
  manageableSpaceForFloor: (
    <Trans
      i18nKey="label.manageableSpaceForFloor"
      defaults="Manageable space for floor {{num}}"
    />
  ),
  maxOccupancy: <Trans i18nKey="label.maxOccupancy" defaults="Max occupancy" />,
  minOccupancy: <Trans i18nKey="label.minOccupancy" defaults="Min occupancy" />,
  modified: <Trans i18nKey="label.modified" defaults="Modified" />,
  name: <Trans i18nKey="label.name" defaults="Name" />,
  no: <Trans i18nKey="label.no" defaults="No" />,
  noBrand: <Trans i18nKey="label.noBrand" defaults="No brand" />,
  noLineTemplatesAssigned: (
    <Trans
      i18nKey="label.noLineTemplatesAssigned"
      defaults="No line templates assigned."
    />
  ),
  noLineTemplatesFound: (
    <Trans
      i18nKey="label.noLineTemplatesFound"
      defaults="No line templates found"
    />
  ),
  noSubContractor: (
    <Trans i18nKey="label.noSubContractor" defaults="no sub-contractor" />
  ),
  noTripsAssigned: (
    <Trans i18nKey="label.noTripsAssigned" defaults="No trips assigned." />
  ),
  noTripsFound: (
    <Trans i18nKey="label.noTripsFound" defaults="No trips found" />
  ),
  noVehicleConnections: (
    <Trans
      i18nKey="label.noVehicleConnections"
      defaults="No vehicle connections"
    />
  ),
  notDefined: <Trans i18nKey="label.notDefined" defaults="Not defined" />,
  number: <Trans i18nKey="label.number" defaults="Number" />,
  numberOfSeats: (
    <Trans i18nKey="label.numberOfSeats" defaults="Number of seats" />
  ),
  numberOfStandingPlaces: (
    <Trans
      i18nKey="label.numberOfStandingPlaces"
      defaults="Number of standing places"
    />
  ),
  odd: <Trans i18nKey="label.odd" defaults="Odd number" />,
  openModificationHistory: (
    <Trans
      i18nKey="label.openModificationHistory"
      defaults="Open modification history"
    />
  ),
  order: <Trans i18nKey="label.order" defaults="Order" />,
  originStop: <Trans i18nKey="label.originStop" defaults="Origin stop" />,
  owner: <Trans i18nKey="label.owner" defaults="Owner" />,
  passengerCountFromTo: (
    <Trans
      i18nKey="label.passengerCountFromTo"
      defaults="Passenger count from - to"
    />
  ),
  planName: <Trans i18nKey="label.planName" defaults="Plan name" />,
  preferVehiclesWithLowerLoadFactor: (
    <Trans
      i18nKey="label.preferVehiclesWithLowerLoadFactor"
      defaults="Prefer vehicles with lower load factor"
    />
  ),
  priorityOrder: (
    <Trans i18nKey="label.priorityOrder" defaults="Priority order" />
  ),
  problematicTransactions: (
    <Trans
      i18nKey="label.problematicTransactions"
      defaults="Problematic transactions"
    />
  ),
  process: <Trans i18nKey="label.process" defaults="Process" />,
  'property.blockingReason': (
    <Trans i18nKey="label.property.blockingReason" defaults="Blocked reason" />
  ),
  'property.hasChildSeatEquipment': (
    <Trans
      i18nKey="label.property.hasChildSeatEquipment"
      defaults="Child seat equipment"
    />
  ),
  'property.hasPowerSocket': (
    <Trans i18nKey="label.property.hasPowerSocket" defaults="Power socket" />
  ),
  'property.hasShower': (
    <Trans i18nKey="label.property.hasShower" defaults="Has shower" />
  ),
  'property.hasTable': (
    <Trans i18nKey="label.property.hasTable" defaults="Has table" />
  ),
  'property.hasToilet': (
    <Trans i18nKey="label.property.hasToilet" defaults="Has toilet" />
  ),
  'property.hasUsbSocket': (
    <Trans i18nKey="label.property.hasUsbSocket" defaults="USB socket" />
  ),
  'property.isBlocked': (
    <Trans i18nKey="label.property.isBlocked" defaults="Blocked" />
  ),
  'property.isByAisle': (
    <Trans i18nKey="label.property.isByAisle" defaults="Aisle seat" />
  ),
  'property.isByLuggageRack': (
    <Trans
      i18nKey="label.property.isByLuggageRack"
      defaults="By luggage rack"
    />
  ),
  'property.isByWheelchairSpace': (
    <Trans
      i18nKey="label.property.isByWheelchairSpace"
      defaults="By wheelchair space"
    />
  ),
  'property.isByWindow': (
    <Trans i18nKey="label.property.isByWindow" defaults="Window seat" />
  ),
  'property.isForOnBoardSalesOnly': (
    <Trans
      i18nKey="label.property.isForOnBoardSalesOnly"
      defaults="For onboard sales only"
    />
  ),
  'property.isInPetFriendlyArea': (
    <Trans
      i18nKey="label.property.isInPetFriendlyArea"
      defaults="Pet-friendly area seat"
    />
  ),
  'property.isReserved': (
    <Trans i18nKey="label.property.isReserved" defaults="Reserved seat" />
  ),
  'property.isSuitableForDisabledPassenger': (
    <Trans
      i18nKey="label.property.isSuitableForDisabledPassenger"
      defaults="Suitable for disabled passenger"
    />
  ),
  qtyTemplates: (
    <Trans
      i18nKey="label.qtyTemplates"
      defaults="(1)[{{count}} template];(2-inf)[{{count}} template];"
    />
  ),
  qtyTrips: (
    <Trans
      i18nKey="label.qtyTrips"
      defaults="(1)[{{count}} trip];(2-inf)[{count}} trips];"
    />
  ),
  range: <Trans i18nKey="label.range" defaults="Range" />,
  reason: <Trans i18nKey="label.reason" defaults="Reason" />,
  recreateSeatGroups: (
    <Trans i18nKey="label.recreateSeatGroups" defaults="Recreate seat groups" />
  ),
  removeFloor: <Trans i18nKey="label.removeFloor" defaults="Remove floor" />,
  removeManageableSpace: (
    <Trans
      i18nKey="label.removeManageableSpace"
      defaults="Remove manageable space"
    />
  ),
  reverse: <Trans i18nKey="label.reverse" defaults="Reverse" />,
  savingChanges: (
    <Trans i18nKey="label.savingChanges" defaults="Saving changes..." />
  ),
  searchResults: (
    <Trans i18nKey="label.searchResults" defaults="Search results" />
  ),
  seatAssignment: (
    <Trans i18nKey="label.seatAssignment" defaults="Seat assignment" />
  ),
  seatAssignmentEnabled: (
    <Trans
      i18nKey="label.seatAssignmentEnabled"
      defaults="Seat assignment enabled"
    />
  ),
  'seatFilters.hide': (
    <Trans i18nKey="label.seatFilters.hide" defaults="Hide seat filters" />
  ),
  'seatFilters.show': (
    <Trans i18nKey="label.seatFilters.show" defaults="Show seat filters" />
  ),
  seatNumber: <Trans i18nKey="label.seatNumber" defaults="Seat number" />,
  seatsWithoutClasses: (
    <Trans
      i18nKey="label.seatsWithoutClasses"
      defaults="Seats without classes"
    />
  ),
  seatsWithoutNumbers: (
    <Trans
      i18nKey="label.seatsWithoutNumbers"
      defaults="Seats without numbers"
    />
  ),
  selectedNumber: (
    <Trans i18nKey="label.selectedNumber" defaults="Selected: {{num}}" />
  ),
  serviceCode: <Trans i18nKey="label.serviceCode" defaults="Service code" />,
  serviceCodes: <Trans i18nKey="label.serviceCodes" defaults="Service codes" />,
  serviceLevel: <Trans i18nKey="label.serviceLevel" defaults="Service level" />,
  show: <Trans i18nKey="label.show" defaults="Show" />,
  sleeperInventoryAllocationRuleset: (
    <Trans
      i18nKey="label.sleeperInventoryAllocationRuleset"
      defaults="Sleeper inventory allocation ruleset"
    />
  ),
  snug: <Trans i18nKey="label.snug" defaults="Snug" />,
  snugAvailabilityWeight: (
    <Trans
      i18nKey="label.snugAvailabilityWeight"
      defaults="Snug availability weight"
    />
  ),
  snugConditionedPropertiesWeight: (
    <Trans
      i18nKey="label.snugConditionedPropertiesWeight"
      defaults="Snug conditioned properties weight"
    />
  ),
  snugLoadFactorWeight: (
    <Trans
      i18nKey="label.snugLoadFactorWeight"
      defaults="Snug load factor weight"
    />
  ),
  snugMatchedPropertiesWeight: (
    <Trans
      i18nKey="label.snugMatchedPropertiesWeight"
      defaults="Snug matched properties weight"
    />
  ),
  snugRankWeight: (
    <Trans i18nKey="label.snugRankWeight" defaults="Snug rank weight" />
  ),
  snugSeats: (
    <Trans
      i18nKey="label.snugSeats"
      defaults="(0)[{{count}} seats];(1)[{{count}} seat];(2-inf)[{{count}} seats];"
    />
  ),
  snugs: <Trans i18nKey="label.snugs" defaults="Snugs" />,
  snugsCount: (
    <Trans
      i18nKey="label.snugsCount"
      defaults="(0)[{{count}} snugs];(1)[{{count}} snug];(2-inf)[{{count}} snugs];"
    />
  ),
  soldOnce: <Trans i18nKey="label.soldOnce" defaults="Sold once" />,
  ['sort.created']: (
    <Trans i18nKey="label.sort.created" defaults="Date created" />
  ),
  ['sort.modified']: (
    <Trans i18nKey="label.sort.modified" defaults="Last modified" />
  ),
  ['sort.name']: <Trans i18nKey="label.sort.name" defaults="Alphabetical" />,
  sortString: <Trans i18nKey="label.sortString" defaults="Sort" />,
  splits: <Trans i18nKey="label.splits" defaults="Splits" />,
  splitsEnabled: (
    <Trans i18nKey="label.splitsEnabled" defaults="Splits enabled" />
  ),
  standingInventoryClass: (
    <Trans
      i18nKey="label.standingInventoryClass"
      defaults="Standing inventory class"
    />
  ),
  startDate: <Trans i18nKey="label.startDate" defaults="Start date" />,
  subContractor: (
    <Trans i18nKey="label.subContractor" defaults="Sub-contractor" />
  ),
  subType: <Trans i18nKey="label.subType" defaults="Subtype" />,
  templateEndDate: (
    <Trans i18nKey="label.templateEndDate" defaults="Template end date" />
  ),
  templateEndDateFrom: (
    <Trans
      i18nKey="label.templateEndDateFrom"
      defaults="Template end date from"
    />
  ),
  templateEndDateTo: (
    <Trans i18nKey="label.templateEndDateTo" defaults="Template end date to" />
  ),
  templateName: <Trans i18nKey="label.templateName" defaults="Template name" />,
  templateStartDate: (
    <Trans i18nKey="label.templateStartDate" defaults="Template start date" />
  ),
  templateStartDateFrom: (
    <Trans
      i18nKey="label.templateStartDateFrom"
      defaults="Template start date from"
    />
  ),
  templateStartDateTo: (
    <Trans
      i18nKey="label.templateStartDateTo"
      defaults="Template start date to"
    />
  ),
  threshold: <Trans i18nKey="label.threshold" defaults="Threshold" />,
  time: <Trans i18nKey="label.time" defaults="Time" />,
  timetable: <Trans i18nKey="label.timetable" defaults="Time table" />,
  totalNumberOfManageablePlaces: (
    <Trans
      i18nKey="label.totalNumberOfManageablePlaces"
      defaults="Total number of manageable places"
    />
  ),
  totalNumberOfPlaces: (
    <Trans
      i18nKey="label.totalNumberOfPlaces"
      defaults="Total number of places"
    />
  ),
  translations: <Trans i18nKey="label.translations" defaults="Translations" />,
  tripDateFromTo: (
    <Trans i18nKey="label.tripDateFromTo" defaults="Trip date (from-to)" />
  ),
  tripManagement: (
    <Trans i18nKey="label.tripManagement" defaults="Trip management" />
  ),
  tripName: <Trans i18nKey="label.tripName" defaults="Trip name" />,
  tripStatus: <Trans i18nKey="label.tripStatus" defaults="Trip status" />,
  tripsFound: (
    <Trans i18nKey="label.tripsFound" defaults="{{count}} trips found" />
  ),
  type: <Trans i18nKey="label.type" defaults="Type" />,
  up: <Trans i18nKey="label.up" defaults="Up" />,
  useDepartureFromLineTemplate: (
    <Trans
      i18nKey="label.useDepartureFromLineTemplate"
      defaults="Use departure times from the line template"
    />
  ),
  usedVehicleWeight: (
    <Trans i18nKey="label.usedVehicleWeight" defaults="Used vehicle weight" />
  ),
  user: <Trans i18nKey="label.user" defaults="User" />,
  validFrom: <Trans i18nKey="label.validFrom" defaults="Valid from" />,
  validTo: <Trans i18nKey="label.validTo" defaults="Valid to" />,
  vehicle: <Trans i18nKey="label.vehicle" defaults="Vehicle" />,
  vehicleCompositionsConnected: (
    <Trans
      i18nKey="label.vehicleCompositionsConnected"
      defaults="Vehicle compositions connected"
    />
  ),
  vehicleLoadFactorWeight: (
    <Trans
      i18nKey="label.vehicleLoadFactorWeight"
      defaults="Vehicle load factor weight"
    />
  ),
  width: <Trans i18nKey="label.width" defaults="Width" />,
  yes: <Trans i18nKey="label.yes" defaults="Yes" />,
  zoomIn: <Trans i18nKey="label.zoomIn" defaults="Zoom in" />,
  zoomOut: <Trans i18nKey="label.zoomOut" defaults="Zoom out" />,
  zoomToFit: <Trans i18nKey="label.zoomToFit" defaults="Zoom to fit" />,
  zoomToPercent: (
    <Trans i18nKey="label.zoomToPercent" defaults="Zoom to {{percent}} %" />
  ),
});
