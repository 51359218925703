import { AuthProviderProps } from 'react-oidc-context';
import { APP_REDIRECT_URI_KEY } from 'wrappers/AuthWrapper';

const isProduction = process.env.NODE_ENV === 'production';

const configuration: AuthProviderProps = {
  checkSessionIntervalInSeconds: 60,
  silentRequestTimeoutInSeconds: 20,
  authority: process.env.REACT_APP_AUTH_URL!,
  client_id: process.env.REACT_APP_IDENTITY_CLIENT_ID!,
  redirect_uri: `${process.env.REACT_APP_BASE_URL}/signin-oidc`,
  automaticSilentRenew: true,
  post_logout_redirect_uri: `${process.env.REACT_APP_BASE_URL}/signout-oidc`,
  silent_redirect_uri: `${process.env.REACT_APP_BASE_URL}/signin-silent`,
  response_type: 'code',
  scope: `openid profile ${process.env.REACT_APP_API_SCOPE}`,
  onSigninCallback: () => {
    const redirectUri = sessionStorage.getItem(APP_REDIRECT_URI_KEY) || '/';
    sessionStorage.removeItem(APP_REDIRECT_URI_KEY);
    window.location.replace(redirectUri);
  },
  monitorSession: isProduction,
};

export default configuration;
