import { type Classifier } from '@fleet/shared/dto/classifier';

export enum ClassificationGroup {
  BED_INVENTORY_SUB_TYPE = 'BED_INVENTORY_SUB_TYPE',
  BED_PROPERTY = 'BED_PROPERTY',
  COMFORT_LEVEL = 'COMFORT_LEVEL',
  COMPARTMENT_PROPERTY = 'COMPARTMENT_PROPERTY',
  CULTURE = 'CULTURE',
  GENDERIZATION_RULE = 'GENDERIZATION_RULE',
  INTERNAL_SUB_TYPE = 'INTERNAL_SUB_TYPE',
  INVENTORY_TYPE = 'INVENTORY_TYPE',
  MANAGEABLE_SPACE_TYPE = 'MANAGEABLE_SPACE_TYPE',
  MOUNTING = 'MOUNTING',
  ORIENTATION = 'ORIENTATION',
  PLACE_NUMBERING_SYSTEM = 'PLACE_NUMBERING_SYSTEM',
  PLACE_SUB_TYPE = 'PLACE_SUB_TYPE',
  SEAT_PROPERTY = 'SEAT_PROPERTY',
  SERVICE_CLASS = 'SERVICE_CLASS',
  SIGN_SUB_TYPE = 'SIGN_SUB_TYPE',
  TRIP_STATUS = 'TRIP_STATUS',
  VEHICLE_COMPOSITION_DIRECTION = 'VEHICLE_COMPOSITION_DIRECTION',
  VEHICLE_TYPE = 'VEHICLE_TYPE',
  // !ClassificationGroups
  BUSINESS_ENTITY = 'BUSINESS_ENTITY',
  INVENTORY_CLASS = 'INVENTORY_CLASS',
  INVENTORY_BLOCKING_REASON = 'INVENTORY_BLOCKING_REASON',
  LINE = 'LINE',
  SUB_CONTRACTOR = 'SUB_CONTRACTOR',
}

export const STANDARD_CLASSIFICATION_GROUPS = [
  ClassificationGroup.BED_INVENTORY_SUB_TYPE,
  ClassificationGroup.BED_PROPERTY,
  ClassificationGroup.COMFORT_LEVEL,
  ClassificationGroup.COMPARTMENT_PROPERTY,
  ClassificationGroup.CULTURE,
  ClassificationGroup.GENDERIZATION_RULE,
  ClassificationGroup.INTERNAL_SUB_TYPE,
  ClassificationGroup.INVENTORY_TYPE,
  ClassificationGroup.MANAGEABLE_SPACE_TYPE,
  ClassificationGroup.MOUNTING,
  ClassificationGroup.ORIENTATION,
  ClassificationGroup.PLACE_NUMBERING_SYSTEM,
  ClassificationGroup.PLACE_SUB_TYPE,
  ClassificationGroup.SEAT_PROPERTY,
  ClassificationGroup.SERVICE_CLASS,
  ClassificationGroup.SIGN_SUB_TYPE,
  ClassificationGroup.TRIP_STATUS,
  ClassificationGroup.VEHICLE_COMPOSITION_DIRECTION,
  ClassificationGroup.VEHICLE_TYPE,
] as const;

export type ClassificationKey = Extract<
  ClassificationGroup,
  (typeof STANDARD_CLASSIFICATION_GROUPS)[keyof typeof STANDARD_CLASSIFICATION_GROUPS]
>;

export interface AvailableLocalization {
  languageId: string;
  name: string;
}

export interface ClassifierWithLocalizations extends Classifier {
  localizations: AvailableLocalization[];
}

export interface ClassificationGroupItem {
  id: ClassificationKey;
  classifications: ClassifierWithLocalizations[];
}

export interface BusinessEntity extends Classifier<number> {
  isMain: boolean;
  contactType: string;
}

export interface ServiceCode {
  id: number;
  code: string;
}

export interface LineDto extends Classifier<number> {
  number: string;
  serviceCodes: ServiceCode[];
  subContractors: Classifier[];
  vehicleCompositions: Array<Classifier<number> & { code: string }>;
}
