import { createAction } from '@reduxjs/toolkit';
import { createAsyncThunk } from 'store/utils';
import type { Pagination } from '@fleet/shared/dto/pagination';
import { api } from '@fleet/shared';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import type {
  TripDto,
  TripEditPayload,
  TripFilterDto,
  TripVehicleAddPayload,
  TripVehicleReplacePayload,
} from 'dto/trip';
import { CompositionElements } from 'dto/composition';
import { Option } from '@fleet/shared/dto/option';
import { tripCurrentSelector } from 'features/trip/tripSelectors';
import { Classifier } from '@fleet/shared/dto/classifier';
import { formatDate, isoDateTimeFormat } from '@fleet/shared/utils/date';

export const setTripFilter =
  createAction<Partial<TripFilterDto>>('trip/setFilter');

const fetchTrips = createAsyncThunk<
  Pagination<TripDto>,
  Partial<TripFilterDto> | undefined
>(
  'trip/fetch',
  async (values, { getState }) =>
    (
      await api.post<Pagination<TripDto>>(
        `/organizations/${currentBusinessEntityIdSelector(
          getState()
        )}/trips/get`,
        values,
        { baseURL: process.env.REACT_APP_API_LM }
      )
    ).data
);

export const setTrips = createAction<Pagination<TripDto> | undefined>(
  'trip/setList'
);
export const searchTrips = createAsyncThunk<
  Pagination<TripDto>,
  Partial<TripFilterDto> | undefined
>('trip/search', async (values, { dispatch, getState }) => {
  if (values) dispatch(setTripFilter(values));
  const state = getState();
  const {
    filter: { tripDateFromTo, ...rest },
  } = state.trip;

  const data = await dispatch(
    fetchTrips({
      ...rest,
      ...(tripDateFromTo && {
        tripDateFrom:
          tripDateFromTo.from &&
          formatDate(tripDateFromTo.from, isoDateTimeFormat.split("'T'")[0]),
        tripDateTo:
          tripDateFromTo.to &&
          formatDate(tripDateFromTo.to, isoDateTimeFormat.split("'T'")[0]),
      }),
    })
  ).unwrap();
  dispatch(setTrips(data));
  return data;
});

export const setTrip = createAction<TripDto | undefined>('trip/setCurrent');
export const getTrip = createAsyncThunk<TripDto, string>(
  'trip/getCurrent',
  async (id, { dispatch }) => {
    const {
      items: [trip],
    } = await dispatch(fetchTrips({ id: +id })).unwrap();
    dispatch(setTrip(trip));
    return trip;
  }
);

export const setCurrentCompositionTrips = createAction<{
  data: TripDto[];
  totalCount: number;
  offset: number;
}>('setCurrentCompositionTrips');
export const getCompositionTrips = createAsyncThunk<
  void,
  Partial<TripFilterDto>
>('trip/compositionTrips', async (values, { dispatch }) => {
  const { items, totalCount, offset } = await dispatch(
    fetchTrips(values)
  ).unwrap();
  dispatch(setCurrentCompositionTrips({ data: items, totalCount, offset }));
});

export const assignCompositionToTrips = createAsyncThunk<
  unknown,
  {
    targetTripIds: Array<number>;
    vehicleCompositionId?: number;
    tripVehicleCompositionId?: number;
    vehicleCompositionDirectionId: string;
  }
>('assignCompositionToTrips', async (payload, { getState }) => {
  await api.post<{ id: number }>(
    `/organizations/${currentBusinessEntityIdSelector(
      getState()
    )}/trip-vehicle-compositions/from-${
      payload.tripVehicleCompositionId ? 'trip-vehicle' : 'vehicle'
    }-composition/bulk`,
    payload
  );
});
export const replaceTripComposition = createAsyncThunk<
  { newCompositionId: number; processId: string },
  TripEditPayload
>(
  'replaceTripComposition',
  async ({ tripRelationId, ...payload }, { getState }) => {
    const { newCompositionId, processId } = (
      await api.put<{ newCompositionId: number; processId: string }>(
        `/organizations/${currentBusinessEntityIdSelector(
          getState()
        )}/trip-vehicle-compositions/${tripRelationId}/vehicle-composition`,
        payload
      )
    ).data;

    return { processId, newCompositionId };
  }
);
export const updateTrip = createAsyncThunk<unknown, TripEditPayload>(
  'updateTrip',
  async ({ tripRelationId, ...payload }, { getState, rejectWithValue }) => {
    try {
      await api.put<{ id: number }>(
        `/organizations/${currentBusinessEntityIdSelector(
          getState()
        )}/trip-vehicle-compositions/${tripRelationId}`,
        payload
      );
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const updateTripComposition = createAsyncThunk<unknown, TripEditPayload>(
  'updateTripComposition',
  async ({ tripRelationId, ...payload }, { getState }) => {
    await api.put<{ id: number }>(
      `/organizations/${currentBusinessEntityIdSelector(
        getState()
      )}/trip-vehicle-compositions/${tripRelationId}/vehicle-composition-vehicles`,
      {
        ...payload,
        tripVehicleCompositionVehicles:
          payload.tripVehicleCompositionVehicles?.map(
            ({
              blockingReason,
              blockingOriginStop,
              blockingDestinationStop,
              ...vehicle
            }) => ({
              ...vehicle,
              blockingReasonId: blockingReason?.id,
              blockingOriginStopId: blockingOriginStop?.id,
              blockingDestinationStopId: blockingDestinationStop?.id,
            })
          ),
      }
    );
  }
);
export const replaceTripCompositionVehicle = createAsyncThunk<
  { processId: string },
  TripVehicleReplacePayload & { tripRelationId: number }
>(
  'replaceTripCompositionVehicle',
  async ({ tripRelationId, ...payload }, { getState }) => {
    const { processId } = (
      await api.put<{ processId: string }>(
        `/organizations/${currentBusinessEntityIdSelector(
          getState()
        )}/trip-vehicle-compositions/${tripRelationId}/vehicle-composition-vehicles/replace`,
        payload
      )
    ).data;

    return { processId };
  }
);
export const addVehicleToTripComposition = createAsyncThunk<
  unknown,
  { tripRelationId: number; vehicleId: number }
>(
  'addVehicleToTripComposition',
  async ({ tripRelationId, vehicleId }, { getState }) => {
    await api.post<{ id: number }>(
      `/organizations/${currentBusinessEntityIdSelector(
        getState()
      )}/trip-vehicle-compositions/${tripRelationId}/vehicle`,
      { vehicleId }
    );
  }
);
export const insetVehicleToTripComposition = createAsyncThunk<
  { processId: string },
  TripVehicleAddPayload & { tripRelationId: number }
>(
  'addTripCompositionVehicle',
  async ({ tripRelationId, ...payload }, { getState }) => {
    const { processId } = (
      await api.put<{ processId: string }>(
        `/organizations/${currentBusinessEntityIdSelector(
          getState()
        )}/trip-vehicle-compositions/${tripRelationId}/vehicle-composition-vehicles/insert`,
        payload
      )
    ).data;

    return { processId };
  }
);
export const removeTripCompositionVehicle = createAsyncThunk<
  { processId: string },
  { compositionId: number; compositionVehicleId: number }
>(
  'removeTripCompositionVehicle',
  async ({ compositionId, compositionVehicleId }, { getState }) => {
    const { processId } = (
      await api.delete<{ id: number; processId: string }>(
        `/organizations/${currentBusinessEntityIdSelector(
          getState()
        )}/trip-vehicle-compositions/${compositionId}/vehicles/${compositionVehicleId}`
      )
    ).data;

    return { processId };
  }
);
export const removeTripRelation = createAsyncThunk<unknown, number>(
  'removeTripRelation',
  async (tripRelationId, { getState }) => {
    await api.delete<never>(
      `/organizations/${currentBusinessEntityIdSelector(
        getState()
      )}/trip-vehicle-compositions/${tripRelationId}`
    );
  }
);
export const updateTripCompositionElements = createAsyncThunk<
  { processId: string },
  CompositionElements
>(
  'updateTripCompositionElements',
  async (payload, { getState, rejectWithValue }) => {
    const state = getState();
    const vehicleId = state.composition.construct!.vehicle!.id!;

    try {
      const { processId } = (
        await api.put<{ id: number; processId: string }>(
          `/organizations/${currentBusinessEntityIdSelector(
            state
          )}/trip-vehicle-composition-vehicles/${vehicleId}/floor-elements`,
          payload
        )
      ).data;

      return { processId };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getTripStops = createAsyncThunk<Array<Option<number>>>(
  'trip/getStops',
  async (_, { getState }) => {
    const trip = tripCurrentSelector(getState());
    if (!trip?.id) return [];
    const {
      data: { routeStops },
    } = await api.get<{
      routeStops: Array<{ routeStopId: number; stop: Classifier }>;
    }>(`/trips/${trip.id}/route-details`, {
      baseURL: process.env.REACT_APP_API_LM,
    });
    return routeStops.map(({ routeStopId, stop }) => ({
      value: routeStopId,
      label: stop.name,
    }));
  }
);
