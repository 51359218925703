import type { FC, ReactNode } from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import { TransLabel } from 'i18n/trans/label';
import { TransLabel as FleetTransLabel } from '@fleet/shared/i18n/trans/label';
import {
  FormField,
  FormProvider,
  Icon,
  Modal,
  ReadOnlyField,
  SelectField,
  Table,
  useForm,
  useFormTable,
  useIndeterminateRowSelectCheckbox,
  useModal,
  useRowEditActions,
} from '@fleet/shared';
import { TransTitle } from 'i18n/trans/title';
import { useDispatch, useSelector } from 'store/utils';
import { InventoryBlockingLevel } from 'dto/organization';
import { InventoryBlockingReasonSelect } from 'components/common/select/InventoryBlockingReason';
import { getAssignedComposition } from 'features/composition/compositionActions';
import {
  compositionConstructSelector,
  compositionConstructVehicleSelector,
  compositionStopsSelector,
} from 'features/composition/compositionSelectors';
import { Button, Stack, Typography } from '@mui/material';
import { metaError } from '@fleet/shared/form/FormField';
import { TableColumns } from '@fleet/shared/components/Table';
import { VehicleBlock } from '@fleet/widget/dto/vehicle';
import { useRowSelect } from 'react-table';
import { TableCellEditableFieldProps } from '@fleet/shared/components/Table/TableCellEditableField';
import { FieldRenderProps } from 'react-final-form';
import {
  FormTableOptions,
  FormTableRowUpdate,
} from '@fleet/shared/hooks/useFormTable';
import { TransButton } from 'i18n/trans/button';
import {
  blockCompositionVehicle,
  deleteCompositionVehicleBlocks,
} from 'features/compositionVehicleBlock/compositionVehicleBlockActions';
import { getTripStops } from 'features/trip/tripActions';
import { getLineTemplateStops } from 'features/lineTemplate/lineTemplateActions';

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    content: {
      paddingLeft: theme.spacing(2),
      borderLeft: `1px solid ${theme.palette.divider}`,
      '& .MuiFormControl-root > label': {
        fontWeight: 400,
      },
    },
    plusMore: {
      marginLeft: theme.spacing(-1),
      color: theme.palette.text.secondary,
    },
  }),
  {
    name: 'CarriageBlockingProperty',
  }
);

const CarriageBlocks = () => {
  const blocks = useSelector(compositionConstructVehicleSelector)!.blocks!;
  const [first, ...other] = blocks;
  const classes = useStyles();
  if (!first) return null;
  return (
    <div className={classes.content}>
      <ReadOnlyField
        labelPosition="left"
        label={<TransLabel i18nKey="reason" />}
        value={first.blockingReason?.name}
      />
      <ReadOnlyField
        labelPosition="left"
        label={<TransLabel i18nKey="firstRouteStop" />}
        value={first.firstRouteStop?.name}
      />
      <ReadOnlyField
        labelPosition="left"
        label={<TransLabel i18nKey="lastRouteStop" />}
        value={first.lastRouteStop?.name}
      />
      {Boolean(other.length) && (
        <Typography className={classes.plusMore}>
          <FleetTransLabel
            i18nKey="plusMore"
            values={{ count: other.length }}
          />
        </Typography>
      )}
    </div>
  );
};

interface CarriageBlockingPropertyProps {}

export const CarriageBlockingProperty: FC<
  CarriageBlockingPropertyProps
> = () => {
  const composition = useSelector(compositionConstructSelector)!;
  const vehicle = useSelector(compositionConstructVehicleSelector)!;
  const { id: compositionId, vehicleCompositionId } = composition;
  const stops = useSelector(compositionStopsSelector);
  const entity = useMemo(
    () => (vehicleCompositionId ? 'line-template' : 'trip'),
    [vehicleCompositionId]
  );
  const dispatch = useDispatch();

  useEffect(() => {
    entity === 'trip' && dispatch(getTripStops());
    entity === 'line-template' && dispatch(getLineTemplateStops());
  }, [dispatch, entity]);

  const data = useMemo(() => vehicle.blocks!, [vehicle.blocks]);

  const Cell = useCallback(
    <D,>(
        name: string,
        field: (
          props: FieldRenderProps<D>,
          cell: TableCellEditableFieldProps<VehicleBlock>
        ) => ReactNode,
        value: (cell: TableCellEditableFieldProps<VehicleBlock>) => ReactNode
      ) =>
      (cell: TableCellEditableFieldProps<VehicleBlock>) => {
        return cell.row.state.editable ? (
          <FormField<D>
            name={`rows[${cell.row.index}].${name}`}
            render={(props) => field(props, cell)}
            required
          />
        ) : (
          value(cell)
        );
      },
    []
  );
  const columns = useMemo<TableColumns<VehicleBlock>>(
    () => [
      {
        accessor: 'blockingReason',
        Header: <TransLabel i18nKey="reason" />,
        Cell: Cell<string>(
          'blockingReason.id',
          ({ input, meta }) => (
            <InventoryBlockingReasonSelect
              label={null}
              level={InventoryBlockingLevel.CARRIAGE}
              value={input.value}
              onChange={input.onChange}
              error={metaError(meta)}
              margin="none"
              showEmptyOption={false}
            />
          ),
          ({ row }) => <>{row.original.blockingReason?.name}</>
        ),
      },
      {
        accessor: 'firstRouteStop',
        Header: <TransLabel i18nKey="firstRouteStop" />,
        editableProps: {},
        Cell: Cell<number>(
          'firstRouteStop.id',
          ({ input, meta }) => (
            <SelectField
              name={input.name}
              label={null}
              value={input.value}
              options={stops}
              error={metaError(meta)}
            />
          ),
          ({ row }) => <>{row.original.firstRouteStop?.name}</>
        ),
      },
      {
        accessor: 'lastRouteStop',
        Header: <TransLabel i18nKey="lastRouteStop" />,
        Cell: Cell<number>(
          'lastRouteStop.id',
          ({ input, meta }) => (
            <SelectField
              name={input.name}
              label={null}
              value={input.value}
              options={stops}
              error={metaError(meta)}
            />
          ),
          ({ row }) => <>{row.original.lastRouteStop?.name}</>
        ),
      },
    ],
    [Cell, stops]
  );

  const { form } = useForm<{ rows: Array<VehicleBlock> }>({
    initialValues: {
      rows: data,
    },
  });

  const getCompositionVehicles = useCallback(
    () =>
      dispatch(
        getAssignedComposition({
          compositionId: `${compositionId}`,
          type: entity,
          orderNumber: vehicle.orderNumber,
        })
      ),
    [compositionId, dispatch, entity, vehicle.orderNumber]
  );
  const onRowUpdate = useCallback<FormTableRowUpdate<VehicleBlock>>(
    async (value) => {
      await dispatch(
        blockCompositionVehicle({
          id: value.id,
          entity,
          compositionId: `${compositionId}`,
          vehicleId: `${vehicle.id}`,
          blockingReasonId: value.blockingReason.id,
          firstRouteStopId: value.firstRouteStop.id,
          lastRouteStopId: value.lastRouteStop.id,
        })
      );
      await getCompositionVehicles();
    },
    [compositionId, dispatch, entity, getCompositionVehicles, vehicle.id]
  );

  const onRowsDelete = useCallback<
    Required<FormTableOptions<VehicleBlock>>['onRowsDelete']
  >(
    async (rows) => {
      await dispatch(
        deleteCompositionVehicleBlocks({
          entity,
          compositionId: `${compositionId}`,
          vehicleId: `${vehicle.id}`,
          ids: rows.filter((row) => Boolean(row.id)).map(({ id }) => id),
        })
      );

      await getCompositionVehicles();
    },
    [compositionId, dispatch, entity, getCompositionVehicles, vehicle.id]
  );

  const table = useFormTable<VehicleBlock>(
    {
      data,
      columns,
      form,
      onRowUpdate,
      onRowsDelete,
    },
    useRowSelect,
    useIndeterminateRowSelectCheckbox,
    useRowEditActions
  );

  const modal = useModal();
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <ReadOnlyField
        labelPosition="left"
        label={<TransLabel i18nKey="property.isBlocked" />}
      >
        <Button variant="text" sx={{ mr: -2 }} onClick={modal.onOpen}>
          <TransLabel i18nKey="addEditBlocking" />
        </Button>
      </ReadOnlyField>
      <CarriageBlocks />
      <Modal
        title={<TransTitle i18nKey="blockingCarriage" />}
        actionButton={<></>}
        open={modal.open}
        onClose={modal.onClose}
        maxWidth="md"
        fullWidth
      >
        <FormProvider form={form}>
          <Table
            caption={
              <Stack justifyContent="space-between" flexDirection="row">
                <Button
                  variant="text"
                  startIcon={<Icon name="plus" />}
                  onClick={table.addRow}
                >
                  <TransButton i18nKey="addNew" />
                </Button>
                <Button
                  startIcon={<Icon name="delete" />}
                  onClick={table.removeSelectedRows}
                  disabled={!Object.keys(table.state.selectedRowIds).length}
                  color="error"
                >
                  <TransButton i18nKey="deleteSelected" />
                </Button>
              </Stack>
            }
            table={table}
          />
        </FormProvider>
      </Modal>
    </div>
  );
};
