import { FC, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { AuthContextProps, useAuth } from 'react-oidc-context';
import { Loader } from '@fleet/shared';

interface AuthWrapperProps {
  appLoading: boolean;
}

export const APP_REDIRECT_URI_KEY = 'REDIRECT_URI';

const isOidcUrl = (pathname: string) =>
  ['/signout-oidc', '/signin-oidc', '/signin-silent'].includes(pathname);
const storeRedirectUriIfNecessary = (pathname: string, search: string) => {
  if (isOidcUrl(pathname)) return;

  const currentRedirectUri = sessionStorage.getItem(APP_REDIRECT_URI_KEY);
  if (currentRedirectUri !== pathname) {
    sessionStorage.setItem(APP_REDIRECT_URI_KEY, pathname + search);
  }
};

const AuthWrapper: FC<AuthWrapperProps> = ({ children, appLoading }) => {
  const auth = useAuth();
  const { pathname, search } = useLocation();

  const performSignInIfNotAuthenticated = useCallback(
    (auth: AuthContextProps) => {
      const { isLoading, isAuthenticated, activeNavigator } = auth;
      const shouldSignIn = !isLoading && !isAuthenticated && !activeNavigator;

      shouldSignIn && auth.signinRedirect();
    },
    []
  );

  useEffect(() => {
    storeRedirectUriIfNecessary(pathname, search);
    performSignInIfNotAuthenticated(auth);
  }, [auth, performSignInIfNotAuthenticated, pathname, search]);

  const isLoading = auth.isLoading || auth.activeNavigator || appLoading;

  if (isLoading) {
    return <Loader active size="fullscreen" />;
  }

  if (!auth.isAuthenticated) {
    return null;
  }

  return <>{children}</>;
};

export default AuthWrapper;
