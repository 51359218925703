import type { FC } from 'react';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ViewerContext } from '@fleet/widget/components/viewer/Context';
import { Rect } from 'react-konva';
import {
  findCollisionEl,
  getGridCoords,
  getNextOrientationId,
} from 'features/utils';
import { ViewerCanvasContainer } from '@fleet/widget/components/viewer/CanvasContainer';
import {
  ViewerCanvas,
  ViewerCanvasDragEvent,
} from '@fleet/widget/components/viewer/Canvas';
import { ViewerTransformer } from '@fleet/widget/components/viewer/Transformer';
import { ViewerControls } from 'components/viewer/ViewerControls';
import {
  KonvaMouseEvent,
  useSelection,
} from '@fleet/widget/hooks/useSelection';
import {
  SelectedState,
  ViewerControlsTooltip,
} from 'components/viewer/ViewerControlsTooltip';
import { useDispatch, useSelector } from 'store/utils';
import { floorElementsSelector } from 'features/classification/classificationSelectors';
import { FloorElement } from '@fleet/widget/dto/floor';
import { ElementCategory } from '@fleet/widget/dto/element';
import { Loader } from '@fleet/shared/mui';
import {
  createFloorElement,
  copyFloorElement,
  deleteElement,
  updateFloorElements,
} from 'features/floor/floorActions';
import _mapValues from 'lodash/mapValues';
import _endsWith from 'lodash/endsWith';
import { useHotkeys } from 'react-hotkeys-hook';
import _debounce from 'lodash/debounce';
import _startsWith from 'lodash/startsWith';
import { useAlert } from 'react-alert';
import _isEmpty from 'lodash/isEmpty';
import { currentVehicleSelector } from 'features/vehicle/vehicleSelector';
import { TransMessage } from 'i18n/trans/message';
import type { ViewerShapeProps } from '@fleet/widget/components/viewer/Shape';
import Konva from 'konva';
import { useSelectionManager } from 'routes/designer/SelectionManager';
import { isCompartment, isPlace } from '@fleet/widget/helpers/element';
import _xor from 'lodash/xor';
import _uniq from 'lodash/uniq';
import {
  deleteVehicleSnug,
  updateVehicleSnug,
} from 'features/vehicle/vehicleSnugActions';

const calcPlaceholderPosition = (
  event: ViewerCanvasDragEvent,
  scale: number,
  maxCoords: { x: number; y: number },
  offset = 0
) => {
  const rect = (event.currentTarget as HTMLDivElement).getBoundingClientRect();

  const { x, y } = getGridCoords(
    {
      x: event.clientX - rect.x + offset,
      y: event.clientY - rect.y + offset,
    },
    scale
  );

  return {
    x: x > maxCoords.x ? maxCoords.x : x,
    y: y > maxCoords.y ? maxCoords.y : y,
  };
};

interface DesignerViewerProps {
  updateSelection(selection: Partial<FloorElement>): Promise<void>;
  actions: {
    rename: (name: string) => void;
    duplicate: () => void;
    delete: () => void;
  };
}

export const DesignerViewer: FC<DesignerViewerProps> = ({
  updateSelection,
  actions,
}) => {
  const alert = useAlert();
  const {
    selectBoxRef,
    currentFloor,
    scale,
    togglePasteMode,
    setCopiedEls,
    pasteActive,
  } = useContext(ViewerContext);
  const currentVehicle = useSelector(currentVehicleSelector);
  const elementsMap = useSelector(floorElementsSelector);

  const [
    { compartment: selectedCompartment, nodes: selectedNodes, snug },
    { setCompartment, setNodes, setSnug },
  ] = useSelectionManager();
  const draggable = useMemo(
    () => !selectedCompartment?.editable && !snug?.editable,
    [selectedCompartment?.editable, snug?.editable]
  );

  const allowSelect = useCallback(
    (element: FloorElement) =>
      snug?.editable
        ? element.typeId === 'PLACE_TYPE.SEAT'
        : selectedCompartment?.editable
        ? isCompartment(element) || isPlace(element)
        : true,
    [selectedCompartment?.editable, snug?.editable]
  );
  const { onClick, transformerRef, setSelected } = useSelection({
    allowSelectReserved: true,
    allowSelectBlocked: true,
    listenMetaKey: draggable,
    allowSelect,
  });
  useEffect(() => {
    transformerRef.current!.detach();
  }, [currentFloor?.id, transformerRef]);
  const getTransformerWithNodes = useCallback(() => {
    const transformer = transformerRef.current!;

    return {
      transformer,
      nodes: transformer.getNodes(),
    };
  }, [transformerRef]);

  const selectBoxInitialState = useMemo(
    () => ({
      x: -1,
      y: -1,
      width: 0,
      height: 0,
      elements: [],
    }),
    []
  );
  const [selectBoxState, setSelectBoxState] = useState<SelectedState>(
    selectBoxInitialState
  );
  useEffect(() => {
    if (!selectedCompartment) return;
    const transformer = transformerRef.current!;
    const stage = transformer.getStage()!;
    const node = stage.findOne(
      (node: Konva.Node) =>
        node.name() === 'compartment' &&
        node.attrs.props.id === selectedCompartment.id
    );
    if (node) {
      node.setAttr('editable', selectedCompartment.editable);
    }
  }, [selectedCompartment, transformerRef]);

  const hideSelectBox = useCallback(() => {
    setSelectBoxState(selectBoxInitialState);
    transformerRef.current?.nodes([]);
    selectBoxRef.current?.setAttr('visible', false);
  }, [selectBoxInitialState, transformerRef, selectBoxRef]);
  const controlTooltipRect = useMemo(() => {
    const { x, y, width, height } = selectBoxState;
    return _mapValues({ x, y, width, height }, (val) => val / scale);
  }, [selectBoxState, scale]);
  const onKeyDown = useCallback((e: KeyboardEvent) => {
    ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key) &&
      e.preventDefault();
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown, false);
    return () => window.removeEventListener('keydown', onKeyDown);
  }, [onKeyDown, transformerRef]);

  useEffect(() => {
    hideSelectBox();
  }, [currentVehicle?.id, hideSelectBox]);

  useEffect(() => {
    setSelectBoxState((state) => ({ ...state, elements: selectedNodes }));
  }, [selectedNodes]);

  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const calculateMaxCoordsForAddElement = useCallback(() => {
    const draggedElSize = {
      height: selectBoxRef.current?.attrs.height - 18,
      width: selectBoxRef.current?.attrs.width - 18,
    };

    return {
      x: currentFloor!.size.x - draggedElSize.width,
      y: currentFloor!.size.y - draggedElSize.height,
    };
  }, [currentFloor, selectBoxRef]);

  const selectedPlaceIds = useMemo(
    () =>
      snug && snug.editable ? snug.placeIds : selectedNodes.map(({ id }) => id),
    [selectedNodes, snug]
  );
  const getShapeSelected = useCallback<
    Required<ViewerShapeProps>['getShapeSelected']
  >((element) => selectedPlaceIds.includes(element.id), [selectedPlaceIds]);

  const handleSelectNodes = useCallback(
    (nodes: Array<FloorElement>) => {
      const [node] = nodes;
      const isCompartment = node && node.category === 'compartment';
      setCompartment(
        isCompartment
          ? node
          : selectedCompartment && {
              ...selectedCompartment,
              editable: false,
            }
      );
      setNodes(nodes);
    },
    [selectedCompartment, setCompartment, setNodes]
  );

  const onSelectHandler = useCallback(
    (elements: Array<FloorElement>) => {
      const { transformer } = getTransformerWithNodes();
      handleSelectNodes(elements);
      setSelectBoxState({
        ...transformer.getClientRect(),
        elements,
      });
      if (!elements.length) return transformer.detach();
    },
    [getTransformerWithNodes, handleSelectNodes]
  );

  const addElement = useCallback(
    async (elPayload: Array<Partial<FloorElement>>) => {
      if (_isEmpty(elPayload)) return;
      setLoading(true);
      await dispatch(createFloorElement(elPayload));
      setLoading(false);
    },
    [dispatch]
  );

  const copyElement = useCallback(
    async (elPayload: Array<FloorElement>) => {
      if (_isEmpty(elPayload)) return;
      setLoading(true);
      await dispatch(copyFloorElement(elPayload));
      setLoading(false);
    },
    [dispatch]
  );

  const onDragOver = useCallback(
    (event: ViewerCanvasDragEvent) => {
      event.preventDefault();
      selectBoxRef.current?.setAttrs({
        ...calcPlaceholderPosition(
          event,
          scale,
          calculateMaxCoordsForAddElement(),
          -8
        ),
        visible: true,
      });
    },
    [calculateMaxCoordsForAddElement, scale, selectBoxRef]
  );

  const onDragEnter = useCallback(onDragOver, [onDragOver]);

  const onDrop = useCallback(
    async (event: ViewerCanvasDragEvent) => {
      const elementId = event.dataTransfer.getData('elementId');
      const coordinates = calcPlaceholderPosition(
        event,
        scale,
        calculateMaxCoordsForAddElement()
      );
      const addedElement = { ...elementsMap![elementId], coordinates };
      const isCollisionPresent = findCollisionEl(
        currentFloor?.elements || [],
        [addedElement],
        true
      );
      if (isCollisionPresent) {
        alert.error(<TransMessage i18nKey="intersectedElementPresent" />, {
          timeout: 3000,
        });
      } else {
        await addElement([
          {
            coordinates,
            elementId,
            category: event.dataTransfer.getData('category') as ElementCategory,
          },
        ]);
      }
      hideSelectBox();
    },
    [
      scale,
      calculateMaxCoordsForAddElement,
      elementsMap,
      currentFloor?.elements,
      hideSelectBox,
      alert,
      addElement,
    ]
  );

  const setPlacesBoundFunc = useCallback((transformer: Konva.Transformer) => {
    const stageSize = transformer.getStage()!.getSize();
    const transformerRect = transformer.getClientRect();
    const nodes = transformer.getNodes();

    nodes.forEach((node) => {
      node.attrs.props.typeId.indexOf('WALL') === -1 && node.moveToTop();
      if (nodes.length > 1) {
        // store initial dragBoundFunc
        node.attrs.prevDragBoundFunc = node.dragBoundFunc();
        const { x, y } = node.getClientRect();
        const transformerOffsetX = transformerRect.x - x;
        const transformerOffsetY = transformerRect.y - y;

        // specify dragBoundFunc relative to transformer
        node.dragBoundFunc((pos) => ({
          x: Math.max(
            -transformerOffsetX,
            Math.min(
              stageSize.width - transformerRect.width - transformerOffsetX + 18,
              pos.x
            )
          ),
          y: Math.max(
            -transformerOffsetY,
            Math.min(
              stageSize.height -
                transformerRect.height -
                transformerOffsetY +
                18,
              pos.y
            )
          ),
        }));
      }
    });
  }, []);

  const handleTransformerDragStart = useCallback(() => {
    const { transformer } = getTransformerWithNodes();
    setPlacesBoundFunc(transformer);
  }, [getTransformerWithNodes, setPlacesBoundFunc]);

  const handleTransformerDragMove = useCallback(() => {
    const { nodes, transformer } = getTransformerWithNodes();
    const { width, height } = _mapValues(
      transformer.getSize(),
      (val) => val / scale
    );
    // smaller grid size for elements with not divisible by 8px dimensions
    const smallerGridToApply =
      nodes.length === 1 && Math.floor(width % 8 || height % 8) ? 4 : undefined;
    nodes.forEach((node) =>
      node.setPosition(
        getGridCoords(
          node.getPosition(),
          1, // node.x().y() return non-scaled coords so scale=1 is used
          smallerGridToApply
        )
      )
    );
  }, [getTransformerWithNodes, scale]);

  const ignoreTransformerDragEnd = useRef(false);
  const updateElementsPosition = useCallback(async () => {
    if (ignoreTransformerDragEnd.current) {
      ignoreTransformerDragEnd.current = false;
      return;
    }
    const { nodes, transformer } = getTransformerWithNodes();
    const selectionEls = nodes.map((node) => ({
      ...node.attrs.props,
      coordinates: node.getPosition(),
    }));
    nodes.forEach((node) => {
      node.attrs.props.typeId.indexOf('WALL') === -1 && node.moveDown();
      if (nodes.length > 1) {
        // return initial dragBoundFunc
        node.dragBoundFunc(node.attrs.prevDragBoundFunc);
      }
    });
    const selectedElIds = selectionEls.map(({ uuid }) => uuid);
    const filteredFloorElements = (currentFloor?.elements || []).filter(
      ({ uuid }) => selectedElIds.indexOf(uuid) === -1
    );
    const isCollisionPresent = findCollisionEl(
      filteredFloorElements, // do not include moved elements in collision detect
      selectionEls,
      true
    );
    const revertDrag = () =>
      nodes.forEach((node) => {
        const { coordinates } = node.attrs.props;
        node.x(coordinates.x);
        node.y(coordinates.y);
      });

    if (pasteActive) {
      if (isCollisionPresent) {
        alert.error(<TransMessage i18nKey="intersectedElementPresent" />, {
          timeout: 3000,
        });
      } else {
        await copyElement(
          selectionEls.filter(
            ({ id, nestedInElementId }) =>
              !nestedInElementId || id === nestedInElementId
          )
        );
      }
      togglePasteMode(false);
      transformer.nodes([]);
      return;
    }

    if (isCollisionPresent) {
      alert.error(<TransMessage i18nKey="intersectedElementPresent" />, {
        timeout: 3000,
      });
      revertDrag();
    } else {
      const { type } = await dispatch(updateFloorElements(selectionEls));
      _endsWith(type, 'rejected') && revertDrag();
      const { transformer } = getTransformerWithNodes();
      setSelectBoxState((prevState) => ({
        ...prevState,
        ...transformer.getClientRect(),
      }));
    }
  }, [
    alert,
    copyElement,
    currentFloor?.elements,
    dispatch,
    getTransformerWithNodes,
    pasteActive,
    togglePasteMode,
  ]);

  const onClickHandler = useCallback(
    (e: KonvaMouseEvent) => {
      onClick(e, async (nodes, deletionNodes) => {
        const [selected, ...elements] = nodes;
        if (snug?.editable) {
          if (!selected) return;
          const { placeIds: previousPlaceIds } = snug;
          const includesSelected = previousPlaceIds.includes(selected.id);
          const placeIds = includesSelected
            ? _xor(previousPlaceIds, [selected.id])
            : _uniq([...previousPlaceIds, selected.id]);
          if (selected && selected.typeId === 'PLACE_TYPE.SEAT') {
            if (snug.id) {
              await (placeIds.length
                ? dispatch(updateVehicleSnug({ id: snug.id, placeIds }))
                : dispatch(deleteVehicleSnug(snug.id))
              ).unwrap();
            }
            setSnug({ ...snug, placeIds, editable: true });
          }
          return;
        }

        if (selected?.category === 'compartment') {
          dispatch(
            updateFloorElements(
              !deletionNodes
                ? elements
                    .filter(
                      (el) =>
                        !el.compartmentId ||
                        (el.compartmentId && el.compartmentId !== selected.id)
                    )
                    .map((node) => ({
                      ...node,
                      compartmentId: selected.id,
                    }))
                : deletionNodes.map((node) => ({
                    ...node,
                    compartmentId: null,
                  }))
            )
          );
        }
        onSelectHandler(nodes);
      });
      if (snug?.editable) {
        setSelected([]);
      }
    },
    [dispatch, onClick, onSelectHandler, setSelected, setSnug, snug]
  );

  const onSelectionRotate = useCallback(async () => {
    const { nodes } = getTransformerWithNodes();
    await updateSelection(
      getNextOrientationId(selectBoxState.elements[0].orientation!)
    );
    onSelectHandler(nodes.map((el) => el.attrs.props));
  }, [
    getTransformerWithNodes,
    onSelectHandler,
    selectBoxState.elements,
    updateSelection,
  ]);

  const onElPaste = useCallback(() => {
    togglePasteMode(true);
    setSelectBoxState(selectBoxInitialState);
    setNodes([]);

    setTimeout(() => {
      const transformer = transformerRef.current!;
      const stage = transformer.getStage()!;
      const pasteEls = stage.find(
        (node: Konva.Node) =>
          node.name() === 'group' &&
          _startsWith(node.attrs.props?.uuid, 'pasted-el')
      );
      if (pasteEls) {
        transformer.setNodes(pasteEls);
        transformer.getNodes().forEach((node) => {
          node.clearCache();
          node.startDrag();
        });
      } else {
        togglePasteMode(false);
      }
    }, 0);
  }, [selectBoxInitialState, setNodes, togglePasteMode, transformerRef]);

  const onSelectionPaste = useCallback(async () => {
    setCopiedEls(
      selectBoxState.elements.map((el) => ({
        ...el,
        ...(el.id === el.nestedInElementId && {
          nestedInElementId: null,
        }),
      }))
    );
    onElPaste();
  }, [setCopiedEls, selectBoxState.elements, onElPaste]);

  const onSelectionDelete = useCallback(async () => {
    if (!selectBoxState.elements.length) return;
    const { transformer, nodes } = getTransformerWithNodes();
    transformer.nodes([]);
    handleTransformerDragStart();
    nodes.forEach((node) => node.setAttr('visible', false));
    const [{ category, id }] = selectBoxState.elements;
    await dispatch(
      deleteElement({
        category,
        ids:
          category === ElementCategory.compartment
            ? [id]
            : selectBoxState.elements.map(({ id }) => id!),
      })
    );
    nodes.forEach((node) => node.setAttr('visible', true));
    setSelectBoxState({ ...transformer.getClientRect(), elements: [] });
    setCompartment(undefined);
    setNodes([]);
  }, [
    dispatch,
    getTransformerWithNodes,
    handleTransformerDragStart,
    selectBoxState.elements,
    setCompartment,
    setNodes,
  ]);

  const debouncedUpdatePosition = _debounce(updateElementsPosition, 300);

  const moveSelection = useCallback(
    (direction) => {
      if (!currentFloor) return;
      const { transformer, nodes } = getTransformerWithNodes();
      const { x: floorWidth, y: floorHeight } = currentFloor.size;
      const { x, y, width, height } = transformer.getClientRect();

      const preventMoving = [
        x < -8 && direction === 'left',
        x - 8 > floorWidth - width && direction === 'right',
        y < -8 && direction === 'up',
        y - 8 > floorHeight - height && direction === 'down',
      ].includes(true);
      if (preventMoving) return;

      nodes.forEach((node) => {
        if (['left', 'right'].includes(direction)) {
          node.x(node.x() + (direction === 'left' ? -8 : 8));
        }

        if (['up', 'down'].includes(direction)) {
          node.y(node.y() + (direction === 'up' ? -8 : 8));
        }
      });

      debouncedUpdatePosition();
    },
    [currentFloor, debouncedUpdatePosition, getTransformerWithNodes]
  );

  useHotkeys(
    'up, left, right, down, del',
    (_, { key }) => {
      key === 'del' ? onSelectionDelete() : moveSelection(key);
    },
    [currentFloor?.size, selectBoxState.elements]
  );

  useHotkeys(
    'ctrl+c, ctrl+v, cmd+c, cmd+v, esc',
    ({ key }) => {
      const transformer = transformerRef.current!;
      if (pasteActive && key === 'Escape') {
        ignoreTransformerDragEnd.current = true;
        transformer.nodes([]);
        togglePasteMode(false);
      } else if (!pasteActive) {
        key === 'c' &&
          setCopiedEls(
            selectBoxState.elements.map((el) => ({
              ...el,
              ...(el.id === el.nestedInElementId && {
                nestedInElementId: null,
              }),
            }))
          );
        key === 'v' && onElPaste();
      }
    },
    { enableOnTags: ['INPUT'] },
    [pasteActive, selectBoxState.elements]
  );

  const onViewerClick = useCallback(
    (e) => {
      if (e.target.tagName === 'CANVAS') return;

      if (selectBoxState.elements.length && !pasteActive) {
        onSelectHandler([]);
      }
    },
    [onSelectHandler, pasteActive, selectBoxState.elements.length]
  );

  return (
    <div className="fleet-viewer scrollable" onClick={onViewerClick}>
      <Loader active={loading} size="container" />
      <ViewerControls actions={actions} />
      <div className="canvas-wrapper">
        <ViewerCanvasContainer
          onDragEnter={onDragEnter}
          onDragOver={onDragOver}
          onDrop={onDrop}
          onDragLeave={hideSelectBox}
        >
          <ViewerCanvas
            onMouseDown={onClickHandler}
            getShapeSelected={getShapeSelected}
            draggable={draggable}
          >
            <Rect
              ref={selectBoxRef}
              dash={[3, 3]}
              stroke="black"
              strokeWidth={0.5}
              visible={false}
            />
            <ViewerTransformer
              ref={transformerRef}
              onDragStart={handleTransformerDragStart}
              onDragMove={handleTransformerDragMove}
              onDragEnd={updateElementsPosition}
            />
            {Boolean(selectBoxState.elements.length) &&
              !loading &&
              draggable && (
                <ViewerControlsTooltip
                  {...controlTooltipRect}
                  elements={selectBoxState.elements}
                  onRotate={onSelectionRotate}
                  onPaste={onSelectionPaste}
                  onDelete={onSelectionDelete}
                />
              )}
          </ViewerCanvas>
        </ViewerCanvasContainer>
      </div>
    </div>
  );
};
