import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransModal = createTrans({
  allocationScoreTemplateDeletionDescription: (
    <Trans
      i18nKey="modal.title.allocationScoreTemplateDeletionDescription"
      defaults="Are you sure you want to delete this allocation score template? It cannot be undone."
    />
  ),
  deleteAllocationScoreTemplate: (
    <Trans
      i18nKey="modal.title.deleteAllocationScoreTemplate"
      defaults="Delete allocation score template"
    />
  ),
  deleteServiceSiAllocationRuleset: (
    <Trans
      i18nKey="modal.title.deleteServiceSiAllocationRuleset"
      defaults="Delete service sleeper inventory allocation ruleset"
    />
  ),
  deleteServiceSiAllocationRulesetDescription: (
    <Trans
      i18nKey="modal.title.deleteServiceSiAllocationRulesetDescription"
      defaults="Are you sure you want to delete service sleeper inventory allocation ruleset"
    />
  ),
  deleteSnug: <Trans i18nKey="modal.title.deleteSnug" defaults="Delete snug" />,
  siAllocationRulesetDeletionTitle: (
    <Trans
      i18nKey="modal.title.siAllocationRulesetDeletionTitle"
      defaults="Delete sleeper inventory allocation ruleset"
    />
  ),
  siAllocationRulesetDeletionDescription: (
    <Trans
      i18nKey="modal.title.siAllocationRulesetDeletionDescription"
      defaults='Are you sure you want to delete sleeper inventory allocation ruleset "{{name}}"? It cannot be undone.'
    />
  ),
  siAllocationRulesetRuleDeletionTitle: (
    <Trans
      i18nKey="modal.title.siAllocationRulesetRuleDeletionTitle"
      defaults="Delete sleeper inventory allocation ruleset rule(s)?"
    />
  ),
  siAllocationRulesetRuleDeletionDescription: (
    <Trans
      i18nKey="modal.title.siAllocationRulesetRuleDeletionDescription"
      defaults="Are you sure you want to delete Sleeper inventory allocation ruleset rule(s)? It cannot be undone."
    />
  ),
});
