import { createAsyncThunk } from 'store/utils';
import {
  BusinessEntity,
  ClassificationGroupItem,
  LineDto,
  ServiceCode,
  STANDARD_CLASSIFICATION_GROUPS,
} from 'dto/classification';
import { api } from '@fleet/shared';
import qs from 'qs';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import { setCurrentBusinessEntity } from 'features/common/commonActions';
import type { InventoryClass } from 'dto/inventoryClass';
import type { PaginationParams } from '@fleet/shared/dto/pagination';
import type { InventoryBlockingReason } from 'dto/organization';
import type { Classifier } from '@fleet/shared/dto/classifier';
import type { ElementResponse } from '@fleet/widget/dto/element';
import { prepareFloorElements } from '@fleet/widget/utils/prepareFloorElements';

export const getClassificationGroups = createAsyncThunk<
  Array<ClassificationGroupItem>
>(
  'getClassificationGroups',
  async () =>
    (
      await api.get<{ items: Array<ClassificationGroupItem> }>(
        `/classifications/groups${qs.stringify(
          {
            ids: Object.values(STANDARD_CLASSIFICATION_GROUPS),
          },
          {
            addQueryPrefix: true,
            skipNulls: true,
            arrayFormat: 'comma',
          }
        )}`,
        {
          baseURL: process.env.REACT_APP_API_BD,
        }
      )
    ).data.items
);
export const getBusinessEntities = createAsyncThunk<Array<BusinessEntity>>(
  'common/businessEntities',
  async (_, { getState, dispatch }) => {
    const businessEntities = (
      await api.get<{ items: BusinessEntity[] }>('/users/business-entities', {
        baseURL: process.env.REACT_APP_API_OM,
      })
    ).data.items;

    const defaultBusinessEntity =
      businessEntities.find(({ isMain }) => isMain) || businessEntities[0];
    dispatch(
      setCurrentBusinessEntity(
        currentBusinessEntityIdSelector(getState()) || defaultBusinessEntity.id
      )
    );

    return businessEntities.filter(
      ({ contactType }) => contactType === 'ORGANIZATION'
    );
  }
);
export const getFloorElementsList = createAsyncThunk(
  'common/currentOrganizationId/vehicle-floor-elements',
  async (_, { getState }) => {
    return prepareFloorElements(
      (
        await api.get<{ floorElements: Array<ElementResponse> }>(
          `/organizations/${currentBusinessEntityIdSelector(
            getState()
          )}/vehicle-floor-elements`
        )
      ).data.floorElements
    );
  }
);
export const getInventoryClasses = createAsyncThunk<
  Array<InventoryClass>,
  PaginationParams | undefined
>(
  'common/inventoryClasses',
  async (params = { limit: 100 }, { getState }) =>
    (
      await api.get(
        `/business-entities/${currentBusinessEntityIdSelector(
          getState()
        )}/inventory-classes?${qs.stringify(params)}`
      )
    ).data.items
);
export const getInventoryBlockingReasons = createAsyncThunk(
  'getInventoryBlockingReasons',
  async (_, { getState }) =>
    (
      await api.get<{ items: Array<InventoryBlockingReason> }>(
        `/carriers/${currentBusinessEntityIdSelector(
          getState()
        )}/blocking-reasons`
      )
    ).data.items
);
export const getLines = createAsyncThunk(
  'getLines',
  async (_, { getState }) =>
    (
      await api.get<{ items: Array<LineDto> }>(
        `/organizations/${currentBusinessEntityIdSelector(getState())}/lines`,
        {
          baseURL: process.env.REACT_APP_API_LM,
        }
      )
    ).data.items
);
export const getServiceCodes = createAsyncThunk(
  'getServiceCodes',
  async (_, { getState }) =>
    (
      await api.get<{ serviceCodes: Array<ServiceCode> }>(
        `/organizations/${currentBusinessEntityIdSelector(
          getState()
        )}/service-codes`
      )
    ).data.serviceCodes
);
export const getSubContractors = createAsyncThunk(
  'getSubContractors',
  async (_, { getState }) =>
    (
      await api.get<{ subcontractors: Array<Classifier> }>(
        `/organizations/${currentBusinessEntityIdSelector(
          getState()
        )}/sub-contractors`
      )
    ).data.subcontractors
);

export const getClassifications = createAsyncThunk(
  'classification/all',
  async (_, { dispatch }) => {
    await Promise.all([
      dispatch(getClassificationGroups()),
      dispatch(getFloorElementsList()),
      dispatch(getInventoryClasses()),
      dispatch(getInventoryBlockingReasons()),
      dispatch(getServiceCodes()),
    ]);
  }
);
